import React from 'react'

// Images
import Route from 'img/route.inline.svg'

// Third Party
import styled from 'styled-components'

const Wrapper = styled.div`
  max-width: 2000px;
  position: relative;

  @media screen and (min-width: 2000px) {
    margin-left: auto;
    margin-right: auto;
  }

  & svg {
    width: 100%;
    height: auto;
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${(props) => props.theme.color.face.main};
    width: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
`

interface ProductRouteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ProductRoute
}

const ProductRoute: React.FC<ProductRouteProps> = () => (
  <Wrapper>
    <Route />
  </Wrapper>
)

export default ProductRoute
